/* eslint-disable */
import axios from "axios";
import { agency } from '../shared/agency'

export const sendEmail = (data, socios) => {

    return new Promise((resolve) => {
        const brevoApiKey = process.env.REACT_APP_BREVO_API_KEY;

        const headers = {
            accept: "application/json",
            "api-key": brevoApiKey,
            "content-type": "application/json"
        };

        const { Base64DoArquivo, NomeDoArquivo } = data;

        let attachment = [];

        if (Base64DoArquivo && NomeDoArquivo) {
            const base64 = Base64DoArquivo.split(";")[1].replace("base64,", "");
            attachment.push({ content: base64, name: NomeDoArquivo });
        }

        const valores = {};
        const { PorteDaEmpresa } = data;
        const { NumeroDeParcelas } = data;
        const parcelas = NumeroDeParcelas === '34x' ? '02 meses de carência' : 0

        let descricaoPorteEmpresa = "";
        switch (PorteDaEmpresa.toLowerCase()) {
            case "me":
                descricaoPorteEmpresa = "ME";
                valores.Valor = "R$ 50.000,00";
                valores.Amortizacao = NumeroDeParcelas
                valores.Carencia = parcelas
                valores.Juro = "1,59%";
                break;
            case "epp":
                descricaoPorteEmpresa = "EPP";
                valores.Valor = "R$ 100.000,00";
                valores.Amortizacao = NumeroDeParcelas
                valores.Carencia = parcelas
                valores.Juro = "1,59%";
                break;
            default:
                break;
        }

        const { AtividadeDaEmpresa } = data;
        let descricaoAtividade = "";

        switch (AtividadeDaEmpresa) {
            case "1":
                descricaoAtividade = "Indústria";
                break;
            case "2":
                descricaoAtividade = "Comércio";
                break;
            case "3":
                descricaoAtividade = "Serviço";
                break;
        }

        const getSexo = (sexo) => {
            switch (sexo) {
                case "0":
                    return "Masculino";
                case "1":
                    return "Feminino";
                default:
                    return "Outro";
            }
        }

        const getEstadoCivil = (estadoCivil) => {
            let descricaoEstadoCivil = "";
            switch (estadoCivil) {
                case "1":
                    descricaoEstadoCivil = "Solteiro(a)";
                    break;
                case "0":
                    descricaoEstadoCivil = "Casado(a)";
                    break;
                case "5":
                    descricaoEstadoCivil = "União Estável";
                    break;
                case "3":
                    descricaoEstadoCivil = "Divorciado(a)";
                    break;
                case "2":
                    descricaoEstadoCivil = "Viúvo(a)";
                    break;

                default:
                    break;
            }
            return descricaoEstadoCivil;
        };

        const { FinalidadeDoCredito } = data;

        let descricaoFinalidadeCredito = "";
        switch (FinalidadeDoCredito.toLowerCase()) {
            case "0":
                descricaoFinalidadeCredito =
                    "Capital de Giro (compra de estoque, pagamento de fornecedores, etc.)";
                break;
            case "15":
                descricaoFinalidadeCredito = "Pagamento 13º Salário";
                break;
            case "1":
                descricaoFinalidadeCredito =
                    "Investimento (veículos, máquinas, equipamentos, ampliações, reformas, etc.)";
                break;
            case "16":
                descricaoFinalidadeCredito = "Marketing";
                break;
            case "17":
                descricaoFinalidadeCredito = "Tecnologia (computadores, sistemas, etc.)";
                break;
            default:
                break;
        }

        const {
            RazaoSocial,
            Cnpj,
            Email,
            TelefoneDaEmpresa,
            EnderecoDaEmpresa,
            BairroDaEmpresa,
            CepDaEmpresa,
            EstadoDaEmpresa,
            CidadeDaEmpresa,
            FaturamentoAnual,
        } = data;

        let html = `<html>
                <head></head>
                <body>
                <p>
                <b>RAZÃO SOCIAL : </b> ${RazaoSocial} <br />
                <b>CNPJ : </b> ${Cnpj.replace(/\D/g, '')} <br />
                <b>EMAIL EMPRESA : </b> ${Email} <br />
                <b>TELEFONE EMPRESA : </b> ${TelefoneDaEmpresa} <br />
                <b>PORTE DA EMPRESA : </b> ${descricaoPorteEmpresa} <br />
                <b>VALOR : </b> ${valores.Valor} <br />
                <b>PRAZO AMORTIZAÇÃO : </b> ${valores.Amortizacao} <br />
                <b>CARÊNCIA : </b> ${valores.Carencia} <br />
                <b>JURO : </b> ${valores.Juro} <br />
                <b>ATIVIDADE : </b> ${descricaoAtividade} <br />
                <b>COOPERATIVA : </b> ${agency.find(a => a.name != undefined).name} <br />
                <b>AGÊNCIA : </b> ${agency.find(a => a.id != undefined).id} <br />
                <b>CEP DA EMPRESA : </b> ${CepDaEmpresa} <br />
                <b>ENDEREÇO DA EMPRESA : </b> ${EnderecoDaEmpresa} <br />
                <b>BAIRRO DA EMPRESA : </b> ${BairroDaEmpresa} <br />
                <b>ESTADO DA EMPRESA : </b> ${EstadoDaEmpresa} <br />
                <b>CIDADE DA EMPRESA : </b> ${CidadeDaEmpresa} <br />
                <b>FATURAMENTO ANUAL : </b> ${FaturamentoAnual} <br />`;

        socios?.forEach((socio, index) => {
            const {
                Nome,
                Cpf,
                Email,
                EstadoCivil,
                CpfConjuge,
                NomeConjuge,
                EmailConjuge,
                Telefone,
                DataDeNascimento,
                NomeDaMae,
                Rg,
                Sexo,
                Naturalidade,
                Cep,
                Endereco,
                Bairro,
                Estado,
                Cidade,
                TelefoneConjuge,
                DataDeNascimentoConjuge,
                NomeDaMaeConjuge,
                RgConjuge,
                SexoConjuge,
                NaturalidadeConjuge,
                CepConjuge,
                EnderecoConjuge,
                BairroConjuge,
                EstadoConjuge,
                CidadeConjuge,
            } = socio;

            const socioNumber = index + 1;
            const estadoCivil = getEstadoCivil(EstadoCivil);

            html += `<b>CPF DO SÓCIO ${socioNumber} : </b> ${Cpf} <br />
              <b>NOME DO SÓCIO ${socioNumber} : </b> ${Nome} <br />
              <b>EMAIL DO SÓCIO ${socioNumber} : </b> ${Email} <br />
              <b>ESTADO CIVIL DO SÓCIO ${socioNumber} : </b> ${estadoCivil} <br />
              <b>TELEFONE DO SÓCIO ${socioNumber} : </b> ${Telefone} <br />
              <b>DATA DE NASCIMENTO DO SÓCIO ${socioNumber} : </b> ${DataDeNascimento} <br />
              <b>NOME DA MÃE DO SÓCIO ${socioNumber} : </b> ${NomeDaMae} <br />
              <b>RG DO SÓCIO ${socioNumber} : </b> ${Rg} <br />
              <b>SEXO DO SÓCIO ${socioNumber} : </b> ${getSexo(Sexo)} <br />
              <b>NATURALIDADE DO SÓCIO ${socioNumber} : </b> ${Naturalidade} <br />
              <b>CEP DO SÓCIO ${socioNumber} : </b> ${Cep} <br />
              <b>ENDEREÇO DO SÓCIO ${socioNumber} : </b> ${Endereco} <br />
              <b>BAIRRO DO SÓCIO ${socioNumber} : </b> ${Bairro} <br />
              <b>ESTADO DO SÓCIO ${socioNumber} : </b> ${Estado} <br />
              <b>CIDADE DO SÓCIO ${socioNumber} : </b> ${Cidade} <br />`;

            if (EstadoCivil === "0" || EstadoCivil === "5") {
                html += `<b>CPF CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${CpfConjuge} <br />
                <b>NOME CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${NomeConjuge} <br />
                <b>EMAIL CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${EmailConjuge} <br />
                <b>TELEFONE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${TelefoneConjuge} <br />
                <b>DATA DE NASCIMENTO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${DataDeNascimentoConjuge} <br />
                <b>NOME DA MÃE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${NomeDaMaeConjuge} <br />
                <b>RG CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${RgConjuge} <br />
                <b>SEXO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${getSexo(SexoConjuge)} <br />
                <b>NATURALIDADE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${NaturalidadeConjuge} <br />
                <b>CEP CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${CepConjuge} <br />
                <b>ENDEREÇO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${EnderecoConjuge} <br />
                <b>BAIRRO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${BairroConjuge} <br />
                <b>ESTADO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${EstadoConjuge} <br />
                <b>CIDADE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${CidadeConjuge} <br />`;
            }
        });

        const {
            NumeroAtualFuncionario,
            ProvaveisEmpregos,
            Autorizo1,
            Autorizo2,
            Autorizo3,
            Autorizo4
        } = data;

        html += `<b>FINALIDADE DO CRÉDITO : </b> ${descricaoFinalidadeCredito}<br />
            <b>QUANTOS EMPREGADOS A EMPRESA POSSUI : </b> ${NumeroAtualFuncionario}<br />
            <b>SERÃO GERADOS NOVOS EMPREGOS? QUANTOS? : </b> ${ProvaveisEmpregos}<br /><br />`;

        html += `Autorizo que os meus dados pessoais (nome, e-mail, telefone e cidade
            onde resido) sejam utilizados exclusivamente para retorno e envio de
            comunicações referentes ao contato solicitado. Após o término do
            tratamento dos dados, os mesmos serão descartados, em conformidade
            com Lei 13.709/18, Lei Geral de Proteção de Dados. Ao informar meus
            dados, eu concordo com a Política de Privacidade. : 
            <b>${Autorizo1 ? "SIM" : "NÃO"}</b><br /><br />`;

        html += `Autorizo Sicoob a ter acesso às informações sobre subsídios e responsabilidades por garantias
        constantes em meu nome, perante Instituições de crédito e registradas no
        Sistema de Informações de crédito do Banco Central (SCR),
        no SISBACEN ou nos sistemas que venham a complementá-lo e/ou a substituí-lo, conforme normas em vigor,
        bem como registros em órgão de proteção ao de crédito.
        Autorizado ainda a concessão de certidão de subsídios municipais, estaduais e federais,
        para fins de análise de crédito. : <b>${Autorizo2 ? "SIM" : "NÃO"}</b><br /><br />`;

        html += `Tenho interesse em consultoria do Sebrae. Autorizo que meus dados sejam realizados ao
        Sebrae para que eu entre em contato a fim de formalizar a consultoria. : <b> ${Autorizo3 ? "SIM" : "NÃO"
            }</b><br/><br/>`;

        html += `Autorizo o compartilhamento de dados e informações a meu respeito com a RS Garanti,
        OSCIP que opera o programa de concessão de crédito municipal e também a Prefeitura de Panambi. : <b> ${Autorizo4 ? "SIM" : "NÃO"
            }</b><br/><br/>`;

        html += `        
        </p>
        </body>
        </html>`;

        const sendEmailTo = process.env.REACT_APP_SEND_EMAIL_TO;
        const sendEmailTo2 = process.env.REACT_APP_SEND_EMAIL_TO2;
        const sendNameTo = process.env.REACT_APP_SEND_NAME_TO;
        
        const body = {
            sender: {
                name: `${RazaoSocial}`,
                email: `${Email}`,
            },
            to: [
                {
                    email: sendEmailTo,
                    name: sendNameTo,
                },
                {
                    email: sendEmailTo2,
                    name: sendNameTo,
                }
            ],
            subject: "CONTATO RS GARANTI",
            htmlContent: `${html}`,
            attachment: attachment,
        };

        axios
            .post('https://api.brevo.com/v3/smtp/email', body, {
                method: 'POST',
                headers: { ...headers },
            })
            .then((retorno) => {
                resolve(retorno);
            })
            .catch((reject) => {
                resolve(reject);
            });
    });
}

export default sendEmail;
