/* eslint-disable */
import React from 'react';
import { useContext } from "react";
import { PopupContext } from '../../context/PopupContext';


const TermosForm = ({ register, errors }) => {

  const { refMyself, refContentCover } = useContext(PopupContext);

  const handleOpenPopup = () => {
    if (!refMyself.current.classList.contains('popup')) {
      refMyself.current.classList.add('popup')
    }

    if (!refContentCover.current.classList.contains('actived')) {
      refContentCover.current.classList.add('actived')
    }
  }

  return (
    <>
      <div className="container termosForm">
        <div className="boxAutorizo1">
          <div>
            <input
              type="checkbox"
              id="autorizo1"
              name="autorizo1"
              {...register('Autorizo1', { required: true })}
            />

            <label htmlFor="autorizo1">
              Autorizo que os meus dados pessoais (nome, e-mail, telefone e cidade
              onde resido) sejam utilizados exclusivamente para retorno e envio de
              comunicações referentes ao contato solicitado. Após o término do
              tratamento dos dados, os mesmos serão descartados, em conformidade
              com Lei 13.709/18, Lei Geral de Proteção de Dados. Ao informar meus
              dados, eu concordo com a <a href='#politica' id='link' onClick={handleOpenPopup}>Política de Privacidade</a>
            </label>
          </div>
          {errors.Autorizo1 && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div className="boxAutorizo2">
          <div>
            <input
              type="checkbox"
              id="autorizo2"
              name="autorizo2"
              {...register('Autorizo2', { required: true })}
            />
            <label htmlFor="autorizo2">
              Autorizo Sicoob a ter acesso às informações sobre subsídios e responsabilidades por garantias
              constantes em meu nome, perante Instituições de crédito e registradas no
              Sistema de Informações de crédito do Banco Central (SCR),
              no SISBACEN ou nos sistemas que venham a complementá-lo e/ou a substituí-lo, conforme normas em vigor,
              bem como registros em órgão de proteção ao de crédito.
              Autorizado ainda a concessão de certidão de subsídios municipais, estaduais e federais,
              para fins de análise de crédito.
            </label>
          </div>
          {errors.Autorizo2 && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div className="boxAutorizo3">
          <div>
            <input
              type="checkbox"
              id="autorizo3"
              name="autorizo3"
              {...register('Autorizo3', { required: true })}
            />
            <label htmlFor="autorizo3">
              Tenho interesse em consultoria do Sebrae. Autorizo que meus dados sejam realizados ao
              Sebrae para que eu entre em contato a fim de formalizar a consultoria.
            </label>
          </div>
          {errors.Autorizo3 && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>

        <div className="boxAutorizo4">
          <div>
            <input
              type="checkbox"
              id="autorizo4"
              name="autorizo4"
              {...register('Autorizo4', { required: true })}
            />
            <label htmlFor="autorizo4">
              Autorizo o compartilhamento de dados e informações a meu respeito com a RS Garanti,
              OSCIP que opera o programa de concessão de crédito municipal e também a Prefeitura de Panambi.
            </label>
          </div>
          {errors.Autorizo4 && <span className='erroForm'>&gt; Obrigatório</span>}
        </div>
        <div className="doc">
          <p>A documentação completa será solicitada somente após análise das informações fornecidas no formulário.</p>
          <p>Após pré-análise, a RS Garanti entrará em contato com sua empresa, preferencialmente por e-mail.
          </p>
        </div>
      </div>
    </>
  )
}

export default TermosForm