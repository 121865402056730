/* eslint-disable */
import { createContext, useRef } from "react"

export const PopupContext = createContext()

export const PopupContextProvider = ({ children }) => {
    const refMyself = useRef(null)
    const refContentCover = useRef(null)

    return (
        <PopupContext.Provider value={{ refMyself, refContentCover }}>
            {children}
        </PopupContext.Provider>
    );
};

