/*eslint-disable*/

//MÁSCARA CNPJ
export const maskCnpj = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})(\d+?)/, '$1')
}

//MÁSCARA INSCRIÇÃO ESTADUAL (AGRICULTOR)
export const maskInscricaoEstadual = (value) => {
    if (!value) return '';
    else return value.replace(/[\D]/g, '').replace(/(\d{3})(\d)/, '$1/$2')
}

//MÁSCARA CNPJ OU INSCRIÇÃO ESTADUAL
export const maskCnpjIE = (digits) => {
    if (!digits) return;
    if (digits.length >= 14)
        return digits.replace(/[\D]/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d)/, '$1-$2').replace(/(-\d{2})(\d+?)/, '$1')
    if (digits.length < 14)
        return digits.replace(/[\D]/g, '').replace(/(\d{3})(\d)/, '$1/$2')
}

//MÁSCARA CPF
export const maskCpf = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
        .replace(/(-\d{2})(\d+?)/, '$1')
}

//MÁSCARA DATA
export const maskData = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d+?)/, '$1')
}

export const maskNumberOfInnstallments = (value) => {
    if (!value) return "";
    return value.replace(/[a-zA-Z]/g, '')
}

export const maskPeriod = (value) => {
    if (!value) return "";
    return value.replace(/[a-zA-Z]/g, '')
}

//MÁSCARA TELEFONE FIXO OU CELULAR NO MESMO CAMPO
export const maskPhoneNumber = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(value[5] != 9 ? /(\d{4})(\d)/ : /(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)/, '$1')
}

//MÁSCARA CEP
export const maskCep = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})(\d+?)/, '$1')
}

//MÁSCARA VALOR MONETÁRIO LIMITADO NO MILHÃO
export const maskCurrency = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        //.replace(/^(\d+)(\d{2})$/, '$1,$2')
        .replace(/^(\d+)(\d{2})/, 'R$ $1,$2')
        .replace(/(\d+)(\d{3})/, '$1.$2')
        .replace(/(\d+)(\d{3})/, '$1.$2')
        .replace(/(\d{3})(\d+?)/, '$1')
}

//MÁSCARA RG
// Esta função recebe um valor (string) e um parâmetro opcional maxLength.
// Ela foi projetada para formatar um número de RG brasileiro adicionando pontos.
export const maskRg = (value, maxLength = 9) => {
    // Se o valor de entrada for falso (null, undefined, string vazia, etc.), retorna uma string vazia.
    if (!value) return '';

    // Remove todos os caracteres não numéricos do valor de entrada e limita o comprimento para maxLength.
    const cleanedValue = value.replace(/[\D]/g, '').substring(0, maxLength);

    // Aplica expressões regulares para adicionar pontos em posições específicas para formatar o número de RG.
    // O formato é XX.XXX.XXX(-X), onde X representa um dígito.
    return cleanedValue.replace(/[\D]/g, '') // Remove novamente caracteres não numéricos.
        .replace(/(\d{2})(\d)/, '$1.$2')   // Adiciona um ponto após os dois primeiros dígitos.
        .replace(/(\d{3})(\d)/, '$1.$2')   // Adiciona um ponto após os próximos três dígitos.
        .replace(/(\d{3})(\d)/, '$1.$2')   // Adiciona um ponto após os próximos três dígitos.
        .replace(/(-\d{1})(\d+?)/, '$1');  // Adiciona um hífen antes do último dígito (se presente).
}

// A expressão regular /[^a-zA-Z]/g é utilizada para substituir todos os caracteres 
// que não são letras por uma string vazia em uma string dada.
export const maskRemoveCharactersNotNumeric = (value) => {
    if (!value) return '';
    return value.replace(/[^a-zA-Z]/g, '')
}

// A expressão regular /[^0-9]/g é utilizada para substituir todos os caracteres 
// que são letras por uma string vazia em uma string dada.
export const maskRemoveLetters = (value) => {
    if (!value) return '';
    return value.replace(/[^0-9]/g, '')
}

// MÁSCARA DE MOEDA
export const currencyToNumber = (value) => {
    return Number(value?.replace(/[^\d,]/g, '').replace(',', '.')).toFixed(2);
}