/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'

const UploadForm = ({ register, setValue, errors, propsSpanRef, setInitialValue }) => {

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const [noSelectedFile, setNoSelectedFile] = useState("")
  const spanRef = useRef(null)

  useEffect(() => {
    propsSpanRef(spanRef)
  }, [noSelectedFile])

  return (
    <div className="container uploadForm">
      <h2
        style={
          {
            color: '#FFFFFF',
            fontWeight: 'normal',
            fontSize: '1.2rem',
            marginBottom: '7.5px'
          }
        }
      >
        PGDAS-D (dos últimos 12 meses –
        para ME e EPP)
      </h2>
      <label
        htmlFor="uploadForm"
        className='bgColorElement'
      >
        <span>
          Anexar arquivo
        </span>
        {noSelectedFile === "" ? (<span>{setInitialValue}</span>) : (<span ref={spanRef}>{noSelectedFile}</span>)}
      </label>
      <input
        {...register(`files`, { required: true })}
        id='uploadForm'
        accept='application/pdf'
        type="file"
        onChange={(e) => {
          const files = e.target.files || []
          if (files.length > 0) {
            toBase64(files[0]).then(b64 => {
              setValue('Base64DoArquivo', b64)
              setValue('NomeDoArquivo', files[0].name)
              setValue('Megabytes', files[0].size)
              setNoSelectedFile(files[0].name)
              // setNoSelectedFile(
              //   // Remove o caminho e obtém apenas o nome do arquivo
              //   e.target.value.split('\\').pop()
              // )
            })
          }
        }

        }
      />
      <p style={
          {
            color: '#FFFFFF',
            fontWeight: 'normal',
            marginTop: '7.5px'
          }
        }>Anexe aqui seu documento (Tamanho máximo: 2 MB)</p>
      {errors.files && <span className='erroForm'>&gt; Obrigatório</span>}
    </div>
  )
}

export default UploadForm