import React from 'react'
import prefeituraDePanambi from '../assets/imgs/prefeitura-de-panambi.jpg'
import sde from '../assets/imgs/secretaria-de-desenvolvimento.jpg'
import salaDoEmpreendedor from '../assets/imgs/sala-do-empreendedor.jpg'
import sicoob from '../assets/imgs/sicoob.jpg'
import sebrae from '../assets/imgs/sebrae.jpg'
import rsGaranti from '../assets/imgs/rsgaranti.jpg'

const Footer = () => {
    return (
        <footer>
            <a href='https://panambi.atende.net/cidadao' target='_blank' rel='noopener noreferrer'><img src={prefeituraDePanambi} alt="Prefeitura de Panambi" /></a>
            <a href='https://panambi.atende.net/cidadao/pagina/secretaria-municipal-de-desenv-economico-e-ambiental' target='_blank' rel='noopener noreferrer'><img src={sde} alt="Secretaria Municipal de Desenvolvimento Econômico e Ambiental" /></a>
            <a href='https://panambi.atende.net/cidadao/pagina/sala-do-empreendedor' target='_blank' rel='noopener noreferrer'><img src={salaDoEmpreendedor} alt="Sala do Empreendedor" /></a>
            <a href='https://sebraers.com.br/' target='_blank' rel='noopener noreferrer'><img src={sebrae} alt="Sebrae" /></a>
            <a href='https://www.sicoob.com.br/web/sicoobcreditaipu/o-sicoob' target='_blank' rel='noopener noreferrer'><img src={sicoob} alt="Sicoob" /></a>
            <a href='https://rsgaranti.org.br' target='_blank' rel='noopener noreferrer'><img src={rsGaranti} alt="RS Garanti" /></a>
        </footer>
    )
}

export default Footer