import React from 'react'

const BxSectionG = () => {
    return (
        <section id="sectionG">
            <div className="container">
                <h2
                    style={
                        {
                            color: '#174724',
                            fontStyle: 'italic',
                            fontWeight: 'normal',
                            fontSize: '5rem',
                            textAlign: 'center',
                            marginBottom: '50px'
                        }
                    }
                >
                    Dúvidas Frequentes <br />
                    <span
                        style={
                            {
                                display: 'block',
                                color: '#089492'
                            }
                        }>Sobre o Programa
                    </span>
                </h2>
                <div>
                    <h3>Sou associado, posso ir até a minha agência solicitar o crédito?</h3>
                    <p>As demandas serão recebidas através do formulário nesta página.
                        O Sicoob ou a RSGaranti farão a análise prévia e retornarão o contato.</p>
                </div>
                <div>
                    <h3>A Garantidora RSGaranti irá cobrar pela prestação da garantia?</h3>
                    <p>Sim. Para saber mais, entre em contato com a RSGaranti através do telefone (54) 3021-0400.</p>
                </div>
                <div>
                    <h3>Tenho dívidas com tributos municipais, posso acessar o crédito?</h3>
                    <p>O crédito também pode ser usado para fazer este tipo de regularização,
                        mas para isso será necessário procurar a prefeitura antes de solicitar o crédito.</p>
                </div>
                <div>
                    <h3>Serão verificados restritivos?</h3>
                    <p>Sim, a análise de crédito será feita normalmente junto a Sicoob e a RSGaranti.</p>
                </div>
                <div>
                    <h3>Para ter acesso ao crédito, preciso abrir uma conta com a Sicoob?</h3>
                    <p>Sim. Se você ainda não é associado à cooperativa, será necessário fazer a abertura de uma conta para ter acesso aos benefícios do programa.</p>
                </div>
                <p>Mais informações, entre em contato, com a Sala do Empreendedor (55) 98419-5864.</p>
            </div>
        </section>
    )
}

export default BxSectionG