import React from 'react'
import panambiGarantiEParceiros from '../assets/imgs/parambi-garanti-e-parceiros.jpeg'
// import logoPanambiGaranti from '../assets/imgs/logo-panambi-garanti.jpg'
// import parceiros from '../assets/imgs/parceiros.jpg'

const BxSectionA = () => {
    return (
        <section id="sectionA">
            <div className="container">
                <div className="intro">
                    <p>O Panambi Garanti é um programa de crédito e capacitação para microempreendedores e empresas de pequeno porte locais. É uma parceria entre a Prefeitura de Panambi, o Sebrae, Sicoob Creditaipu, com a carta garantia da RS Garanti, como a garantidora de crédito.</p>
                    <p>O programa é voltado para empreendedores que exercem ou buscam exercer atividade produtiva de geração de renda, envolvendo indústria, comércio e serviços. A iniciativa serve como um agente de transformação que visa construir não apenas negócios, mas trajetórias de sucesso pessoal.</p>
                    <p>Com o Panambi Garanti cada empreendedor é capacitado a ser o arquiteto de seus próprios sonhos, conectando oportunidades que impulsionam vidas.</p>
                </div>
                <img src={panambiGarantiEParceiros} alt="Logo Panambi Garanti e Parceiros" />
                {/* <div className='logos'>
                    <img id="logoPanambiGaranti" src={logoPanambiGaranti} alt="Logo Panambi Garanti" />
                    <img src={parceiros} alt="Parceiros" />
                </div> */}
            </div>
        </section>
    )
}

export default BxSectionA