import './assets/css/App.css';
import Header from './components/Header';
import BxSectionA from './components/BxSectionA';
import BxSectionB from './components/BxSectionB';
import BxSectionC from './components/BxSectionC';
import BxSectionD from './components/BxSectionD';
import BxSectionE from './components/BxSectionE';
import BxSectionF from './components/BxSectionF';
import BxSectionG from './components/BxSectionG';

import Form from './components/Form';
import Footer from './components/Footer';
import { Politica } from './components/popup/Politica';

function App() {
  return (
    <>
      <Header />
      <BxSectionA />
      <BxSectionB />
      <BxSectionC />
      <BxSectionD />
      <BxSectionE />
      <BxSectionF />
      <Form />
      <BxSectionG />
      <Politica />
      <Footer />
    </>
  );
}

export default App;
