import React from 'react'
import ico4 from '../assets/imgs/ico-4.png'
import ico5 from '../assets/imgs/ico-5.png'
import ico6 from '../assets/imgs/ico-6.png'
import ico7 from '../assets/imgs/ico-7.png'

const BxSectionC = () => {
    return (
        <>
            <section id="sectionC">
                <div className="container">
                    <h2 style={
                        {
                            color: '#FFFFFF',
                            fontWeight: 'normal',
                            fontSize: '2.125rem',
                            textAlign: 'right'
                        }
                    }>
                        Condições e
                        <br />
                        <strong style={
                            {
                                fontStyle: 'italic',
                                fontSize: '3.5rem',
                            }
                        }>taxas diferenciadas
                        </strong>
                    </h2>
                    <div className="cards">
                        <figure>
                            <img src={ico4} alt="Crédio para qualquer necessidade" />
                            <figcaption><strong>Taxa de juros</strong> <br />pré-fixada de 1,59% a.m</figcaption>
                        </figure>
                        <figure>
                            <img src={ico5} alt="Condições exclusivas" />
                            <figcaption><strong>Prazo de pagamento</strong> <br />até 36 meses com carência de até 02 meses</figcaption>
                        </figure>
                        <figure>
                            <img src={ico6} alt="Valor disponível em poucos dias" />
                            <figcaption><strong>Volumes</strong> <br />de até R$ 150 mil, conforme porte da empresa</figcaption>
                        </figure>
                        <figure>
                            <img src={ico7} alt="Valor disponível em poucos dias" />
                            <figcaption><strong>Garantia</strong> <br />aval dos sócios e carta de garantia da RS Garanti</figcaption>
                        </figure>
                    </div>

                    <span style={{ color: '#fff', textAlign: 'center' }}>* Condições mediante disponibilidade e aprovação do crédito (capacidade de pagamento e sem restrições)</span>
                </div>
            </section>
        </>
    )
}

export default BxSectionC