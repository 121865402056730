import React from 'react'
import microEmpresa from '../assets/imgs/micro-empresa.jpg'
import empresaDePequenoPorte from '../assets/imgs/empresa-de-pequeno-porte.jpg'
import ico8 from '../assets/imgs/ico-8.png'

const BxSectionE = () => {
    return (
        <section id="sectionE">
            <div className="container">
                <h2
                    style={{
                        color: '#FFFFFF',
                        fontWeight: 'normal',
                        fontSize: '2.125rem',
                        textAlign: 'right'
                    }}
                >
                    Quem pode contar com o <br />
                    <strong
                        style={
                            {
                                fontStyle: 'italic',
                                fontSize: '3.5rem',
                            }
                        }>
                        Panambi Garanti
                    </strong>
                </h2>
                <article>
                    <div>
                        <h3>Microempresa</h3>
                        <h4>Com receita bruta anual igual ou inferior a R$ 360 mil</h4>
                        <h5>Limite de crédito:</h5>
                        <span>R$ 75.000,00 em 36 parcelas (sem carência) de até R$ 2.709,16</span>
                        <span>R$ 75.000,00 em 34 parcelas (02 meses de carência) de até R$ 2.918,34</span>
                        <span>* Custo da garantia: até R$ 3.105,00</span>
                    </div>
                    <figure>
                        <img src={microEmpresa} alt="Micro empresa" />
                    </figure>
                </article>
                <article>
                    <div>
                        <h3>Empresa de <br />Pequeno Porte</h3>
                        <h4>Faturamento anual igual ou inferior a R$ 4,8 milhões</h4>
                        <h5>Limite de crédito:</h5>
                        <span>R$ 150.000,00 em 36 parcelas (sem carência) de até R$ 5.418,32</span>
                        <span>R$ 150.000,00 em 34 parcelas (02 meses de carência) de até R$ 5.836,69</span>
                        <span>* Custo da garantia: até R$ 6.210,00</span>
                    </div>
                    <figure>
                        <img src={empresaDePequenoPorte} alt="Empresa de pequeno porte" />
                    </figure>
                </article>
                <div id="detalhes">
                    <img src={ico8} alt="Detalhes" />
                    <ul>
                        <li>Sujeito a análise de crédito. Parceria simuladas sem IOF.</li>
                        <li>Terão acesso a linha aassociados que apresentarem no mínimo de <strong>01 ano de faturamento completo</strong>, declarados nos documentos indicados e necessários abaixo.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default BxSectionE